import React, { useRef, useEffect, useState } from "react";
import * as tt from "@tomtom-international/web-sdk-maps";
import * as ttapi from "@tomtom-international/web-sdk-services";

const Map = ({ shipment }) => {
  console.log(shipment);

  const coords = shipment?.coords;

  const mapElement = useRef();
  const [map, setMap] = useState({});
  // AQUI DEBEN COLOCARSE LAS CORDENADAS INICIALES
  const [search, setSearch] = useState({
    latitude: coords.latitude ?? 0,
    longitude: coords.longitude ??0,
  });

  const convertToPoints = (lngLat) => {
    return {
      point: {
        latitude: lngLat.lat,
        longitude: lngLat.lng,
      },
    };
  };

  const drawRoute = (geoJson, map) => {
    if (map.getLayer("route")) {
      map.removeLayer("route");
      map.removeSource("route");
    }
    map.addLayer({
      id: "route",
      type: "line",
      source: {
        type: "geojson",
        data: geoJson,
      },
      paint: {
        "line-color": "#4a90e2",
        "line-width": 6,
      },
    });
  };

  const addDeliveryMarker = (lngLat, map) => {
    const element = document.createElement("div");
    element.className = "marker-delivery";
    new tt.Marker({
      element: element,
    })
      .setLngLat(lngLat)
      .addTo(map);
  };

  useEffect(() => {
    const origin = {
      lng: search.longitude,
      lat: search.latitude,
    };
    const destinations = [];

    let map = tt.map({
      key: process.env.REACT_APP_TOMTOM_KEY,
      container: mapElement.current,
      stylesVisibility: {
        trafficIncidents: true,
        trafficFlow: true,
      },
      center: [search.longitude, search.latitude],
      zoom: 13,
    });

    setMap(map);

    const addMarker = () => {
      const popupOffset = {
        bottom: [0, -35],
      };
      const popup = new tt.Popup({
        offset: popupOffset,
      }).setHTML("Ubicacion de tu paquete");

      const element = document.createElement("div");
      element.className = "marker";

      const marker = new tt.Marker({
        draggable: false,
        element: element,
      })
        .setLngLat([search.longitude, search.latitude]) // AQUI SE DEBEN COLOCAR LAS CORDENADAS DIRECTAS DEL API
        .addTo(map);
      // CORDENADAS CUANDO EL MARKER TRUCK SE ARRASTRA TIPO DRAG AND DROP
      marker.on("dragend", () => {
        const lngLat = marker.getLngLat();

        setSearch({
          longitude: lngLat.lng,
          latitude: lngLat.lat,
        });
      });

      marker.setPopup(popup).togglePopup();
    };
    addMarker();

    // CORDENADAS DEL LOS DESTINOS
    const sortDestinations = (locations) => {
      const pointsForDestinations = locations.map((destination) => {
        return convertToPoints(destination);
      });
      const callParameters = {
        key: process.env.REACT_APP_TOMTOM_KEY,
        destinations: pointsForDestinations,
        origins: [convertToPoints(origin)],
      };
      return new Promise((resolve, reject) => {
        ttapi.services
          .matrixRouting(callParameters)
          .then((matrixAPIResults) => {
            const results = matrixAPIResults.matrix[0];
            const resultsArray = results.map((result, index) => {
              return {
                location: locations[index],
                drivingtime: result.response.routeSummary.travelTimeInSeconds,
              };
            });
            resultsArray.sort((a, b) => {
              return a.drivingtime - b.drivingtime;
            });
            const sortedLocations = resultsArray.map((result) => {
              return result.location;
            });
            resolve(sortedLocations);
          });
      });
    };
    //RECALCULANDO RUTAS
    const recalculateRoutes = () => {
      sortDestinations(destinations).then((sorted) => {
        sorted.unshift(origin);

        ttapi.services
          .calculateRoute({
            key: process.env.REACT_APP_TOMTOM_KEY,
            locations: sorted,
          })
          .then((routeData) => {
            const geoJson = routeData.toGeoJson();
            drawRoute(geoJson, map);
          });
      });
    };

    // CLICK EN EL MAPA PARA EL DELIVERY O DESTINO , SE PASAN LAS CORDENAS DEL DESTINO VIA API
    /*  map.on("click", (e) => {
      destinations.push(e.lngLat);
      addDeliveryMarker(e.lngLat, map);
      recalculateRoutes();
    }); */

    return () => map.remove();
  }, [search.latitude, search.longitude]);

  // console.log(search.latitude)
  // console.log(search.longitude)

  return (
    <div className="h-full">
      {/* only load if map is going to render */}
      {map ? (
        <>
          {/*  <div className="app">
            <h1>Where to?</h1>
            <div className="search-bar">
              <p>Latitude:</p>
              <input
                type="number"
                id="latitude"
                className="latitude"
                placeholder={search.latitude}
              />
              <p>Longitude:</p>
              <input
                type="number"
                id="longitude"
                className="longitude"
                name="longitude"
                placeholder={search.longitude}
              />
              <button
                className="search-btn"
                onClick={(e) => {
                  setSearch({
                    latitude: document.getElementById("latitude").value,
                    longitude: document.getElementById("longitude").value,
                  });
                }}
              >
                Search
              </button>
            </div>

           
            <p>You can hold down "Ctrl" when clicking to tilt map!</p>
          </div> */}
          <div ref={mapElement} className="map h-full w-full"></div>
        </>
      ) : null}
    </div>
  );
};

export default Map;
