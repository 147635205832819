import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const GoogleAutocomplete = (props) => {
    return (
        <div>
            <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_PLACES_API_KEY}
                autocompletionRequest={{componentRestrictions: {country: ["mx","gt", "us","ca", "bz"]}}}
                {...props}
            />
        </div>
    );
}

export default GoogleAutocomplete;
