import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  CardHeader,
  Button,
  Collapse,
} from "reactstrap";

//components
import { toastSuccess, toastError } from "../../../component/common/toast";
import { AiOutlineEdit } from "@react-icons/all-files/ai/AiOutlineEdit";

//actions
import {
  toastSuccessAction,
  toastErrorAction,
} from "../../../redux/toast/actions";

//const
import { BASE_URL } from "../../../config/constants";
import { getShipments } from "../../../redux/shipments/actions";
import { getShipmentStatusColorClass, shipmentStatus } from "../../../data";
import { updateShipmentStatus } from "../../../services/updateShipmentStatus";
import { getOperatorName } from "../../../utils";

const Events = (props) => {
  const dispatch = useDispatch();
  const { shipments } = useSelector((state) => state.Shipment);
  const { user } = useSelector((state) => state.Auth);

  const makeEvent = (text, shipmentId) => {
    fetch(BASE_URL + "/addEvent", {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({
        shipmentId,
        text,
      }),
    })
      .then((res) => res.json())
      .then((result) => {
        dispatch(toastSuccessAction());
        toastSuccess("El Evento se ha creado correctamente");
        dispatch(getShipments(user.type));
      })
      .catch((error) => {
        dispatch(toastErrorAction());
        toastError("Ha ocurrido un ERROR al crear el Evento");
        console.log(error);
      });
  };

  return (
    <Fragment>
      <Breadcrumb parent="Tracker" title="Eventos" />
      <Container fluid={true}>
        <Row>
          {shipments.map((item, index) => {
            return (
              <EventItem
                sm="12"
                xl="6"
                key={index}
                item={item}
                user={user}
                makeEvent={makeEvent}
              />
            );
          })}
        </Row>
      </Container>
    </Fragment>
  );
};

const EventItem = ({ item, makeEvent, user }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [statusValue, setStatusValue] = useState(item.status ?? "");
  const dispatch = useDispatch();
  // const [isDescription, setIsDescription] = useState(false);

  return (
    <Col sm="12" xl="6">
      <Card>
        <CardHeader>
          <h5 className="default-text mb-0 f-w-700 f-18">{item.id}</h5>
          <ul style={{ marginTop: 20 }}>
            <li className="digits">
              {" "}
              <span className="font-bold">Salida:</span> {item.departure}
            </li>
            <li className="digits" style={{ marginTop: 5 }}>
              <span className="font-bold">Destino:</span> {item.destination}
            </li>
            <li className="digits" style={{ marginTop: 5 }}>
              <span className="font-bold"> Operado por</span>:{" "}
              {getOperatorName(item)}
            </li>
            <li className="digits" style={{ marginTop: 5 }}>
              <span className="font-bold">Descripción:</span> {item.description}
            </li>
            {/*<li className="font-bold" style={{ marginTop: 5 }}>
                <Button color="primary"
                  onClick={() => setIsDescription(!isDescription)}
                  aria-expanded={isDescription}
                  style={{ marginBottom: '1rem' }}>
                    Detalles
                </Button>
                <Collapse isOpen={isDescription}>
                        <p>{item.description}</p>
                </Collapse>
            </li>*/}
          </ul>
          <ul style={{ marginTop: 10 }}>
            <li>
              {isEditing ? (
                <Input
                  type="select"
                  name="select"
                  id="status"
                  className="form-control digits"
                  value={statusValue}
                  onChange={async (e) => {
                    const status = e.target.value;
                    setIsEditing(false);
                    setStatusValue(status);
                    await updateShipmentStatus({
                      shipmentId: item._id,
                      status,
                      user,
                      dispatch,
                    });
                  }}
                >
                  <option>-Elige una opción-</option>
                  {shipmentStatus.map((status) => (
                    <option key={status} value={status}>
                      {status}
                    </option>
                  ))}
                </Input>
              ) : (
                <div className="">
                  <span
                    className={`text-white px-2 py-1 ${getShipmentStatusColorClass(
                      statusValue
                    )}`}
                  >
                    {statusValue}
                  </span>
                  <button
                    type="button"
                    className="btn btn-outline-success py-1 px-2"
                    onClick={() => setIsEditing(true)}
                    style={{ marginLeft: 10 }}
                  >
                    <AiOutlineEdit />
                  </button>
                </div>
              )}
            </li>
          </ul>
        </CardHeader>
        <CardBody>
          <ul style={{ marginBottom: 20 }}>
            <li>
              <small style={{ fontWeight: "700" }}>Eventos:</small>
            </li>
          </ul>
          {item.events.map((record) => {
            return (
              <h6
                key={record._id}
                style={{ marginBottom: 5, fontWeight: "500" }}
              >
                {record.text}
                <hr></hr>
              </h6>
            );
          })}
          <form
            style={{ marginTop: 15 }}
            onSubmit={async (e) => {
              e.preventDefault();
              await makeEvent(e.target[0].value, item._id);
              e.target[0].value = "";
            }}
          >
            <Input
              className="form-control input-air-primary"
              type="text"
              placeholder="Ingresa un evento..."
            />

            <div className="mt-2">
              <small>Presiona ENTER para enviar Evento.</small>

              <Button color="primary" type="submit" className="d-block mt-2">
                Crear evento
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Events;
