import {BASE_URL} from "../../config/constants";
import {getDataLocalStorage, setDataLocalStorage,} from "../../utils/localStorage";
import {USER_SIGN_IN, USER_SIGN_IN_FAILED, USER_SIGN_IN_SUCCESS, USER_SIGN_OUT,} from "./actionTypes";
import {toastError} from "../../component/common/toast";

export const signIn = (data, type) => async (dispatch) => {
  const url = type === "carrier" ? "/signinCarrier" : "/signinDriver";

  dispatch({
    type: USER_SIGN_IN,
    payload: null,
  });
  const { email, password } = data;

  const resp = await fetch(BASE_URL + url, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  }).then((res) => res.json());
  if (resp.error) {
    dispatch({
      type: USER_SIGN_IN_FAILED,
      payload: resp.error,
    });
    toastError(resp.error);
  } else {
    console.log("resp", resp);
    setDataLocalStorage("jwt", resp.token);
    setDataLocalStorage(
      "user",
      JSON.stringify({ ...resp[type], type, jwt: resp.token })
    );
    dispatch({
      type: USER_SIGN_IN_SUCCESS,
      payload: { user: { ...resp,...resp[type], type }, jwt: resp.token }, // TODO: when the user is signed in, email is undefined - FIXED
    });
  }
};

export const validateAuth = () => (dispatch) => {
  const user = JSON.parse(getDataLocalStorage("user"));

  dispatch({
    type: USER_SIGN_IN_SUCCESS,
    payload: { user, jwt: user?.jwt },
  });
};

export const signOut = () => (dispatch) => {
  dispatch({
    type: USER_SIGN_OUT,
    payload: null,
  });
  localStorage.clear();
};
