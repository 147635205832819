import React, {createContext, Fragment, useEffect, useState,} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import {Provider, useSelector} from "react-redux";
import store from "./store/index";
import App from "./App";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {routes} from "./router/route";
import ConfigDB from "./data/customizer/config";

// Auth
import Login from "./component/screens/authentication/login";
import Register from "./component/screens/authentication/register";

//Shipment
import ShipmentPage from "./component/screens/Shipment/shipment";
import {ToastContainer} from "react-toastify";
// import Unlock from "./component/screens/Shipment/components/unlock";

//Export Context
export const UserContext = createContext();

const Root = (props) => {
    const [anim, setAnim] = useState("");
    const animation =
        localStorage.getItem("animation") || ConfigDB.data.router_animation;
    const abortController = new AbortController();


    useEffect(() => {
        setAnim(animation);
        const layout = localStorage.getItem("layout_version");
        document.body.classList.add(layout);
        const color = localStorage.getItem("color");
        document
            .getElementById("color")
            .setAttribute("href", `./assets/css/${color}.css`);
        console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
        console.disableYellowBox = true;
        return function cleanup() {
            abortController.abort();
        };
    }, []);

    return (
        <Switch>
            <Route path="/login">
                <Login/>
            </Route>
            <Route path="/registro">
                <Register/>
            </Route>
            <Route path="/envio/:shipmentId">
                <ShipmentPage/>
            </Route>
            {/* <Route path="/unlock">
                <Unlock/>
            </Route> */}
            <Fragment>
                <App>
                    <Route
                        exact
                        path="/"
                        render={() => {
                            return <Redirect to="/inicio"/>;
                        }}
                    />

                    <TransitionGroup>
                        {routes.map(({path, Component}) => (
                            <Route key={path} exact path={path}>
                                {({match}) => (
                                    <CSSTransition
                                        in={match != null}
                                        timeout={500}
                                        classNames={anim}
                                        unmountOnExit
                                    >
                                        <div>
                                            <Component/>
                                        </div>
                                    </CSSTransition>
                                )}
                            </Route>
                        ))}
                    </TransitionGroup>
                </App>
            </Fragment>
        </Switch>
    );
};

const Main = ({children}) => {
    return (
        <>
            <Fragment>
                <Provider store={store}>
                    <Toast/>
                    <BrowserRouter>
                        {children}
                    </BrowserRouter>
                </Provider>
            </Fragment>
        </>
    );
};

const Toast = ({children}) => {
    const {progressBarColor} = useSelector((state) => state.Toast);
    return (
        <>
            {children}
            <ToastContainer
                progressStyle={{
                    background: progressBarColor,
                }}
                theme="colored"
                autoClose={5000}
                position="top-right"
                hideProgressBar={false}
                closeOnClick
            />
        </>
    );
};

ReactDOM.render(
    <Main>
        <Root/>
    </Main>, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
