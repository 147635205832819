import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";

//constants
import { carrier, driver } from "../../../config/constants";

//actions
import { signIn, validateAuth } from "../../../redux/auth/actions";
import {toastError} from "../../common/toast";
import {toastErrorAction} from "../../../redux/toast/actions";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.Auth);
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState("carrier");

  const validateEmailExp =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const PostData = () => {
    if (!validateEmailExp.test(email)) {
      dispatch(toastErrorAction())
      toastError("Por favor ingresa un correo valido");
      return;
    }
    dispatch(signIn({ email, password }, userType));
  };

  useEffect(() => {
    if (user) {
      history.push("/inicio/resumen");
    } else {
      history.push("/login");
    }
  }, [dispatch, user]);

  useEffect(() => {
    dispatch(validateAuth());
  }, []);

  const toggleform = () => {
    document.querySelector(".cont").classList.toggle("s--signup");
  };

  return (
    <div className="page-wrapper">
      <Container fluid={true} className="p-0">
        {/*  <!-- login page start--> */}
        <div className="authentication-main m-0">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="h-100-d-center">
                    <div className="cont text-center b-light">
                      <div>
                        <Form className="theme-form">
                          <div className="logo-wrapper">
                            <img
                              style={{ marginBottom: 35 }}
                              src={require("../../../assets/images/logo/logo.png")}
                              alt=""
                            />
                          </div>
                          <h4>Bienvenido</h4>
                          <FormGroup>
                            <Label className="col-form-label pt-0">
                              Correo
                            </Label>
                            <Input
                              className="btn-pill"
                              type="text"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Password</Label>
                            <Input
                              className="btn-pill"
                              type="password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </FormGroup>

                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input bg-primary"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio1"
                              defaultChecked
                              value={carrier}
                              onChange={(value) =>
                                setUserType(value.target.value)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Administrador
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value={driver}
                              onChange={(value) =>
                                setUserType(value.target.value)
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio2"
                            >
                              Operador
                            </label>
                          </div>
                          {/*
                          <div className="checkbox p-0">
                            <Input id="checkbox1" type="checkbox" />
                            <Label for="checkbox1">Remember me</Label>
                          </div>
                          */}
                          <FormGroup className="form-row mt-3 mb-0">
                            <Button
                              color="primary btn-block"
                              onClick={() => PostData()}
                            >
                              Entrar
                            </Button>
                          </FormGroup>
                          {/*
                          <div className="login-divider"></div>
                          */}
                          <div className="social mt-3">
                            <Row form className="btn-showcase">
                              <Col md="4" sm="6">
                                {/*
                                <Button color="social-btn btn-fb">
                                  Facebook
                                </Button>
                              */}
                              </Col>
                              <Col md="4" sm="6">
                                {/*
                                <Button color="social-btn btn-twitter">
                                  Twitter
                                </Button>
                              */}
                              </Col>
                              <Col md="4" sm="6">
                                {/*
                                <Button color="social-btn btn-google">
                                  Google +{" "}
                                </Button>
                              */}
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      </div>
                      <div className="sub-cont">
                        <div className="img">
                          <div className="img__text m--up">
                            <h2>Version Alpha</h2>
                            <p>
                              <span>version de prueba abierta</span>
                              <br />
                              <span>Shipbay solutions 1.0.0.1 alpha</span>
                            </p>
                          </div>
                          <div className="img__text m--in">
                            <h2>¿Ya tienes cuenta?</h2>
                            <p>Entra con tu correo y contraseña.</p>
                          </div>
                          {/*
                          <div className="img__btn" onClick={toggleform}>
                            <span className="m--up">Registrate</span>
                            <span className="m--in">Entra</span>
                          </div>
                          */}
                        </div>
                        <div>
                          <Form className="theme-form">
                            <div className="logo-wrapper">
                              <img
                                style={{ marginBottom: 35 }}
                                src={require("../../../assets/images/logo/logo.png")}
                                alt=""
                              />
                            </div>
                            <h4 className="text-center">
                              Nueva cuenta de administrador
                            </h4>
                            <Row form>
                              <Col md="12">
                                <FormGroup>
                                  <Input
                                    className="btn-pill"
                                    type="text"
                                    placeholder="First Name"
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="12">
                                <FormGroup>
                                  <Input
                                    className="btn-pill"
                                    type="text"
                                    placeholder="Last Name"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <FormGroup>
                              <Input
                                className="btn-pill"
                                type="text"
                                placeholder="User Name"
                              />
                            </FormGroup>
                            <FormGroup>
                              <Input
                                className="btn-pill"
                                type="password"
                                placeholder="Password"
                              />
                            </FormGroup>
                            <Row form>
                              <Col sm="4">
                                <Button color="primary" type="submit">
                                  Sign Up
                                </Button>
                              </Col>
                              <Col sm="8">
                                <div className="text-left mt-2 m-l-20">
                                  Are you already user?  
                                  <a
                                    className="btn-link text-capitalize"
                                    href="login.html"
                                  >
                                    Login
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        {/* <!-- login page end--> */}
      </Container>
    </div>
  );
};

export default Login;
