import React, { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

//components
import { toastError, toastSuccess } from "../../common/toast";

//actions
import {
  toastErrorAction,
  toastSuccessAction,
} from "../../../redux/toast/actions";

//const
import { BASE_URL } from "../../../config/constants";

const NewDriver = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [lastname, setLastname] = useState("");
  function getRandomString(length) {
    var randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  const postDriver = (e) => {
    e.preventDefault();
    //email format verification
    if (
      !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      )
    ) {
      console.log("error");
    }
    fetch(BASE_URL + "/signupDriver", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        'Accept': 'application/json',
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({
        email,
        password,
        name,
        lastname,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.error) {
          console.log("error");
          dispatch(toastErrorAction());
          toastError("Ha ocurrido un ERROR al crear el Driver");
        } else {
          history.push("/tracker/operadores");
          dispatch(toastSuccessAction());
          toastSuccess("El Driver se ha creado correctamente");
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch(toastErrorAction());
        toastError("Ha ocurrido un ERROR al crear el Driver");
      });
  };

  return (
    <Fragment>
      <Breadcrumb parent="Tracker" title="Nuevo Operador" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Registra un operador</h5>
              </CardHeader>
              <CardBody className="dropdown-basic">
                <form className="theme-form" onSubmit={postDriver}>
                  <div className="form-group">
                    <label for="form-group">Nombre</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1" className="col-form-label">
                      Apellido
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="lastname"
                      value={lastname}
                      onChange={(e) => setLastname(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1" className="col-form-label">
                      Email
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label for="exampleInputEmail1" className="col-form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      className="form-control"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></input>
                  </div>
                  <div className="text-sm-left">
                    <button className="btn btn-primary-gradien" type="submit">
                      Registrar Operador
                    </button>
                  </div>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewDriver;
