import {
  PROGRESS_SUCCESS,
  PROGRESS_ERROR,
  PROGRESS_INFO,
  PROGRESS_WARNING,
} from "./actionTypes";

export const INITIAL_STATE = {
  progressBarColor: "#000",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PROGRESS_SUCCESS:
      return {
        ...state,
        progressBarColor: "#28a745",
      };
    case PROGRESS_ERROR:
      return {
        ...state,
        progressBarColor: "#dc3545",
      };

    case PROGRESS_INFO:
      return {
        ...state,
        progressBarColor: "#17a2b8",
      };
    case PROGRESS_WARNING:
      return {
        ...state,
        progressBarColor: "#ffc107",
      };

    default:
      return state;
  }
};
