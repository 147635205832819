import { BASE_URL } from "../../config/constants";
import {
  GET_SHIPMENT,
  GET_SHIPMENT_FAILED,
  GET_SHIPMENT_SUCCESS,
} from "./actionTypes";

export const getShipments = (userType) => async (dispatch) => {
  const url =
    userType === "carrier" ? "/myShipments/carrier" : "/myShipments/driver";

  dispatch({
    type: GET_SHIPMENT,
    payload: null,
  });

  const result = await fetch(BASE_URL + url, {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      Authorization: "Bearer " + localStorage.getItem("jwt"),
    },
  }).then((res) => res.json());

  console.log("result", result);

  if (result.error) {
    dispatch({
      type: GET_SHIPMENT_FAILED,
      payload: null,
    });
  } else {
    const { shipments } = result;
    console.log("shpiments", shipments);
    dispatch({
      type: GET_SHIPMENT_SUCCESS,
      payload: { shipments: shipments || [] },
    });
  }
};
