import { useController } from "react-hook-form";
import React from "react";
import classNames from "classnames";
import { Input } from "reactstrap";

const InputField = ({
  nameField,
  control,
  rules,
  isRequired = false,
  variant = "input",
  selectOpts,
  children,
  label,
  ...props
}) => {
  const { placeholder } = props;
  const {
    field,
    fieldState: { invalid, error, isTouched },
  } = useController({
    name: nameField,
    control,
    rules: {
      required: isRequired
        ? {
            value: true,
            message: `El campo ${`${
              placeholder ?? label
            }`.toLocaleLowerCase()} es requerido`,
          }
        : false,
      ...rules,
    },
  });

  return (
    <div className="form-group">
      <label htmlFor={nameField}>{label}</label>
      <div className="p-relative">
        {typeof children === "function" ? (
          children(field, props)
        ) : (
          <>
            {variant === "textarea" ? (
              <textarea
                row="3"
                cols="50"
                type="text"
                {...field}
                {...props}
                className={classNames("form-control", {
                  "is-invalid": invalid,
                })}
              ></textarea>
            ) : null}

            {variant === "select" ? (
              <Input
                {...field}
                {...props}
                type="select"
                className="form-control digits"
                valid={isTouched ? !invalid : undefined}
              >
                <option value="-1">-Elige una opción-</option>
                {selectOpts.map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </Input>
            ) : null}

            {variant === "input" || !variant ? (
              <input
                {...field}
                {...props}
                className={classNames("form-control", {
                  "is-invalid": invalid,
                })}
              />
            ) : null}
          </>
        )}
      </div>
      {invalid ? (
        <p className="text-danger">{error?.message ?? "error"}</p>
      ) : null}
    </div>
  );
};

export default InputField;
