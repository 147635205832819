import React, { useState, useEffect } from "react";
import { Container, CardBody } from "reactstrap";
import { useParams } from "react-router-dom";
import Background from "../../../assets/images/background.jpg";

//components
import { ShowInformationShipments, ValidateOtp } from "./components";

//const
import { BASE_URL } from "../../../config/constants";

const ShipmentPage = (props) => {
  const { shipmentId } = useParams();
  const [shipment, setShipment] = useState(null);
  const [accessToInformation, setAccessToInformation] = useState(false);

  useEffect(() => {
    getShipmentId();
  }, []);

  const getShipmentId = async () => {
    const result = await fetch(BASE_URL + `/shipment/${shipmentId}`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    }).then((res) => res.json());

    if (result.shipment) {
      setShipment(result.shipment);
      console.log("result.shipment", result.shipment);
    }
  };

  return (
    <Container
      fluid={true}
      className="p-0"
      style={{
        backgroundColor: "#F6F9FB",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: "100vh",
      }}
    >
      <div className="w-full">
        {accessToInformation ? (
          <ShowInformationShipments
            //enviar events o la data a mustrar
            data={{ events: shipment ? shipment?.event : [] }}
            shipment={shipment}
          />
        ) : (
          <ValidateOtp
            data={{ shipment }}
            actions={{ setAccessToInformation }}
          />
        )}
      </div>
    </Container>
  );
};

export default ShipmentPage;
