export const shipmentStatus = ['Saliendo', 'En Transito', 'Arribó', 'Completado', 'Retrasado', 'Cancelado'];

export const shipmentStatusColors = {
    saliendo: 'bg-yellow-500',
    en_transito: 'bg-blue-500',
    "arribó": 'bg-blue-800',
    completado: "bg-green-500",
    retrasado: "bg-red-500",
    cancelado: "bg-red-500",
}

export const getShipmentStatusColorClass = (status = "") => {
    return shipmentStatusColors[`${status}`.toLocaleLowerCase().replace(" ", "_")] ?? 'bg-gray-500'
}
