import {BASE_URL} from "../config/constants";
import {toastErrorAction, toastSuccessAction} from "../redux/toast/actions";
import {toastError, toastSuccess} from "../component/common/toast";
import {getShipments} from "../redux/shipments/actions";

export const updateShipmentStatus = ({shipmentId,status,user,dispatch}) => {
        fetch(`${BASE_URL}/updateStatus/${shipmentId}`, {
            method: "put",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
            body: JSON.stringify({
                status
            }),
        })
            .then((res) => res.json())
            .then((result) => {
                console.log("result", result);
                dispatch(toastSuccessAction());
                toastSuccess("El estado se ha cambiado correctamente");
                dispatch(getShipments(user.type));
            })
            .catch((error) => {
                dispatch(toastErrorAction());
                toastError("Ha ocurrido un ERROR al actualizar el estado");
                console.log(error);
            });
    };