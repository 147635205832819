import React, {Fragment, useEffect, useRef} from "react";
import {BrowserRouter, useHistory} from "react-router-dom";
import Loader from "./component/common/loader/loader";
import Header from "./component/common/header/header";
import Sidebar from "./component/common/sidebar/sidebar";
import Rightsidebar from "./component/common/sidebar/rightsidebar";
import Footer from "./component/common/footer/footer";
import "react-toastify/dist/ReactToastify.css";
import '@tomtom-international/web-sdk-maps/dist/maps.css'

import {useDispatch, useSelector} from "react-redux";

//components
import {toastSuccess} from "./component/common/toast";

//actions
import {validateAuth} from "./redux/auth/actions";
import {toastSuccessAction} from "./redux/toast/actions";

import "react-datepicker/dist/react-datepicker.css";
// Component refactor to show only a toast when user is logged in

const AppWrapper = ({user,children}) =>{
  const dispatch = useDispatch();
  useEffect(() => {
      dispatch(toastSuccessAction());
      toastSuccess(`Bienvenido ${user.email}`);
  }, []);


  return (<BrowserRouter>
    <Fragment>
      <div className="page-wrapper">
        <div className="page-body-wrapper">
          <Header />
          <Sidebar />
          <Rightsidebar />
          <div className="page-body">{children}</div>
          <Footer />
        </div>
      </div>
    </Fragment>
  </BrowserRouter>)
}

const App = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Auth);
  const history = useHistory();
  const ref = useRef(localStorage.getItem("last_location"));

  useEffect(() => {
    dispatch(validateAuth());
  }, []);



  useEffect(() => {
    if (user) {
      history.push(ref.current ?? "/");
    } else {
      history.push("/login");
    }
  }, [user]);


  return (
   <>
     <Loader forceClose={!!user} />
     {
       user ?  <AppWrapper user={user}>
         {children}
       </AppWrapper> : null
     }
   </>
  );
};

export default App;
