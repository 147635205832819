import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Trash } from "react-feather";

//const
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { BASE_URL } from "../../../config/constants";
import { getShipments } from "../../../redux/shipments/actions";
import { getShipmentStatusColorClass } from "../../../data";
import { getOperatorName } from "../../../utils";

const Shipments = (props) => {
  const { shipments } = useSelector((state) => state.Shipment);
  const { user } = useSelector((state) => state.Auth);
  const dispatch = useDispatch();

  const deletePost = (shipmentId) => {
    fetch(BASE_URL + `/deleteShipment/${shipmentId}`, {
      method: "delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(result);
        dispatch(getShipments(user.type));
      });
  };
  return (
    <Fragment>
      <Breadcrumb parent="Tracker" title="Envíos" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="activity-table table-responsive recent-table">
                  <table className="table table-bordernone">
                    <thead>
                      <tr>
                        <td>
                          <p className="mb-0">Salida</p>
                        </td>
                        <td>
                          <p className="mb-0">Destino</p>
                        </td>
                        <td>
                          <p className="mb-0">Código</p>
                        </td>
                        <td>
                          <p className="mb-0">Operado por</p>
                        </td>
                        <td>
                          <p className="mb-0">Status</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {shipments.map((item, index) => {
                        return (
                          <tr key={item.id + item.departure + index}>
                            <td style={{ maxWidth: "200px" }} className="mr-2">
                              <h6 className="f-w-700 text-truncate" style={{ paddingRight: "35px" }}>
                                {item.departure}
                              </h6>
                            </td>
                            <td style={{ maxWidth: "200px" }} className="mr-2">
                              <h6 className="f-w-700 text-truncate" style={{ paddingRight: "35px" }}>
                                {item.destination}
                              </h6>
                            </td>
                            <td>
                              <h5 className="default-text mb-0 f-w-700 f-18">
                                {item.id}
                              </h5>
                            </td>
                            <td>
                              <h6 className="f-w-700">
                                {getOperatorName(item)}
                              </h6>
                            </td>
                            <td>
                              <span
                                className={`text-white px-2 py-1 ${getShipmentStatusColorClass(
                                  item.status
                                )}`}
                              >
                                {item.status}
                              </span>
                            </td>
                            <td>
                              <button
                                className="badge badge-danger"
                                onClick={() => deletePost(item._id)}
                              >
                                <Trash />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Shipments;
