import { combineReducers } from "redux";
import Customizer from "./customizer/reducer";
import Auth from "./auth/reducer";
import Toast from "./toast/reducer";
import Shipment from "./shipments/reducer";

const reducers = combineReducers({
  Customizer,
  Auth,
  Toast,
  Shipment,
});

export default reducers;
