import React, { Fragment, useEffect, useState, forwardRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";

//components
import { toastError, toastSuccess } from "../../common/toast";
import { useForm } from "react-hook-form";

//actions
import {
  toastErrorAction,
  toastSuccessAction,
} from "../../../redux/toast/actions";

//const
import { BASE_URL } from "../../../config/constants";
import { shipmentStatus } from "../../../data";
import Inputfield from "../../common/form/inputs/inputfield";
import dayjs from "dayjs";
import { getShipments } from "../../../redux/shipments/actions";
import GooglePlacesAutocomplete from "../../common/form/autocomplete/google";

function TDate(compareDate) {
  const UserDate = compareDate;
  const ToDate = new Date();

  if (new Date(UserDate).getTime() <= ToDate.getTime()) {
    return false;
  }
  return true;
}

const CustomInput = forwardRef(({ value, onClick }, ref) => (
  <button className="form-control" onClick={onClick} ref={ref}>
    {value}
  </button>
));

const NewShipment = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [id, setId] = useState("");
  const [mydrivers, setDrivers] = useState([]);

  const { user } = useSelector((state) => state.Auth);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    // reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      status: "",
      departure: "",
      destination: "",
      eta: new Date(),
      description: "",
      operatedBy: "",
    },
  });

  useEffect(() => {
    fetch(BASE_URL + "/myDrivers", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
      .then((res) => res.json())
      .then((result) => {
        setDrivers(result.mydrivers);
      });

    generateCodeOtp();
  }, []);

  function generateCodeOtp(length = 10) {
    const randomChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    setId(result);
  }

  const postShipment = (values) => {
    console.log(values);
    fetch(BASE_URL + "/createShipment", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
      body: JSON.stringify({
        id,
        ...values,
        eta: dayjs(values.eta).format("YYYY-MM-DD"),
        departure: values.departure?.label,
        destination: values.destination?.label,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);

        if (data.error) {
          console.log("error");
          // M.toast({html:data.error,classes:"#d32f2f red darken-2"})
          dispatch(toastErrorAction());
          toastError("Ha ocurrido un ERROR al crear el Shipment");
        } else {
          // M.toast({html:"Posted!",classes:"#1de9b6 teal accent-3"})
          dispatch(getShipments(user.type));
          history.push("/tracker/envios");
          dispatch(toastSuccessAction());
          toastSuccess("El Shipment se ha creado correctamente");
        }
      })
      .catch((error) => {
        dispatch(toastErrorAction());
        toastError("Ha ocurrido un ERROR al crear el Shipment");
        console.log(error);
      });
  };

  return (
    <Fragment>
      <Breadcrumb parent="Tracker" title="Nuevo Envío" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Crea un envío nuevo</h5>
                <span>asigna a un operador.</span>
              </CardHeader>
              <CardBody className="dropdown-basic">
                <div className="theme-form">
                  <div className="form-group">
                    <label htmlFor="form-group">
                      Código de identificación (Autogenerado)
                    </label>
                    <input
                      type="text"
                      className="form-control mb-1"
                      value={id}
                      disabled
                    />
                    <p className="text-warning">
                      Por favor guarde este codigo OTP para ver el seguimiento
                      de su envío.
                    </p>
                  </div>
                  <Inputfield
                    control={control}
                    isRequired
                    nameField="internalNumber"
                    label="Número Interno"
                    placeholder="Agrega un Numero interno"
                  />
                  <Inputfield
                    control={control}
                    nameField="departure"
                    label="Salida"
                    isRequired
                    placeholder="Dirección de Salida"
                  >
                    {(field) => (
                      <GooglePlacesAutocomplete
                        selectProps={{
                          value: field.value,
                          ref: field.ref,
                          onChange: field.onChange,
                        }}
                      />
                    )}
                  </Inputfield>
                  <Inputfield
                    control={control}
                    nameField="destination"
                    label="Destino"
                    isRequired
                    placeholder="Dirección de Llegada"
                  >
                    {(field) => (
                      <GooglePlacesAutocomplete selectProps={field} />
                    )}
                  </Inputfield>
                  <Inputfield
                    control={control}
                    nameField="description"
                    label="Descripción"
                    isRequired
                    placeholder="(Descripción del envío, número de guía interno, características de entrega, requerimientos, etc)"
                    variant="textarea"
                  />
                  <Inputfield
                    control={control}
                    isRequired
                    nameField="status"
                    label="Status"
                    placeholder="Agrega una Descripción"
                    variant="select"
                    selectOpts={shipmentStatus}
                  />
                  <Inputfield
                    control={control}
                    rules={{
                      required: true,
                    }}
                    nameField="eta"
                    label="Fecha Estimada de llegada."
                  >
                    {(field, props) => {
                      return (
                        <DatePicker
                          selected={field.value}
                          onChange={field.onChange}
                          customInput={<CustomInput />}
                          minDate={new Date()}
                        />
                      );
                    }}
                  </Inputfield>
                  <Inputfield
                    control={control}
                    rules={{
                      validate: (value) =>
                        !(value === "") || "Por favor selecciona un operador",
                    }}
                    nameField="operatedBy"
                    label="Operador"
                  >
                    {(field, props) => {
                      return (
                        <Col>
                          {mydrivers.map((item) => (
                            <Label
                              key={item.id + item.name}
                              className="d-block"
                              htmlFor={item._id}
                            >
                              <Input
                                className="radio_animated"
                                {...field}
                                id={item._id}
                                type="radio"
                                value={item._id}
                              />
                              {item.name} {item.lastname}
                            </Label>
                          ))}
                        </Col>
                      );
                    }}
                  </Inputfield>

                  <div className="text-sm-left">
                    <button
                      className="btn btn-primary"
                      onClick={handleSubmit(postShipment)}
                    >
                      Crear viaje
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewShipment;
