import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { getShipmentStatusColorClass } from "../../../data";

const Share = (props) => {
  const { shipments } = useSelector((state) => state.Shipment);

  return (
    <Fragment>
      <Breadcrumb parent="Tracker" title="Eventos" />
      <Container fluid={true}>
        <Row>
          {shipments.map((item, index) => {
            return <ShareItem key={index} item={item} />;
          })}
        </Row>
      </Container>
    </Fragment>
  );
};

const ShareItem = ({ item }) => {
  const [isCopied, setIsCopied] = useState(false);

  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    navigator.clipboard
      .writeText(textAreaRef.current.value)
      .then((r) => setIsCopied(true));
  }

  useEffect(() => {
    let timer;
    if (isCopied) {
      timer = setTimeout(() => {
        setIsCopied(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [isCopied]);

  return (
    <Col sm="12" xl="6">
      <Card>
        <CardHeader>
          <h5 className="default-text mb-0 f-w-700 f-18">{item.id}</h5>
          <ul style={{ marginTop: 20 }}>
            <li className="digits">Salida: {item.departure}</li>
            <li className="digits" style={{ marginTop: 5 }}>
              Destino: {item.destination}
            </li>
          </ul>
          <ul style={{ marginTop: 10 }}>
            <span
              className={`text-white px-2 py-1 my-1 ${getShipmentStatusColorClass(
                item.status
              )}`}
            >
              {item.status}
            </span>
          </ul>
        </CardHeader>
        <CardBody>
          <textarea
            ref={textAreaRef}
            className="hidden"
            defaultValue={`SHIPBAY SOLUTIONS:\n${item.carriedBy.companyName}/nTe comparto tu link de rastreo de envio.\nhttps://shipbay.solutions/envio/${item._id}\nCódigo OTP: ${item.id}\nSalida: ${item.departure}\nDestino: ${item.destination} `}
          />
          <div className="clipboaard-container" onClick={copyToClipboard}>
            <p className="card-description">
              Copia y pega este mensaje a tus clientes.
            </p>
            <div
              className={`border rounded card-body ${
                isCopied ? "border-success" : ""
              }`}
              id="clipboardExample3"
            >
              {isCopied ? (
                <span
                  className="badge badge-success"
                  style={{
                    position: "absolute",
                    right: "70px",
                  }}
                >
                  Copied!
                </span>
              ) : null}

              <span>
                <medium style={{ fontWeight: "700" }}>
                  SHIPBAY SOLUTIONS:
                </medium>
              </span>
              <br />
              <span>
                <medium style={{ fontWeight: "700" }}>
                  {item.carriedBy.companyName}
                </medium>
              </span>
              <br />
              <span>Te comparto tu link de rastreo de envio.</span>
              <br />
              <ul>
                <a
                  target="_blank"
                  href={`https://shipbay.solutions/envio/${item._id}`}
                >
                  shipbay.solutions/envio/{item._id}
                </a>
              </ul>
              <br />
              <span>
                <medium style={{ fontWeight: "700" }}>
                  Código OTP: {item.id}
                </medium>
              </span>
              <br />
              <span>Salida: {item.departure}</span>
              <br />
              <span>Destino: {item.destination}</span>
              <br />
            </div>
            {/*
              <div class="mt-3">
                <button class="btn btn-primary btn-clipboard" type="button" data-clipboard-action="copy" data-clipboard-target="#clipboardExample3"><i class="fa fa-copy"></i>Copiar</button>
              </div>
              */}
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Share;
