import React from "react";
import { Row } from "reactstrap";
import Map from "./Map";
import { getShipmentStatusColorClass } from "../../../../data";
import dayjs from "dayjs";
import { humanizeDuration } from "../../../../utils/date";
import { getOperatorName } from "../../../../utils";

export function ShowInformationShipments({ shipment }) {

  console.log(shipment);
  return (
    <div className="bg-white mx-auto p-5 w-full md:w-[80%] grid md:grid-cols-2 shadow-lg h-screen my-4"
      style={{
         borderRadius: "25px"
      }}>
      <div className="logo-wrapper flex flex-column max-h-[80%] max-h-screen overflow-y-scroll text-center">
        <img
          style={{
            width: "155px",
          }}
          className="mx-auto"
          alt=""
          src="https://res.cloudinary.com/shipbay/image/upload/v1644706613/Defaults/place-holder_dszlsf.png"
        />
        <div style={{ marginTop: 15 }} className="">
          <h4>{shipment.carriedBy.companyName}</h4>
        </div>
        <span>status:</span>
        <div className="">
          <span
            className={`text-white px-2 py-1 my-1 ${getShipmentStatusColorClass(
              shipment.status
            )}`}
          >
            {shipment.status}
          </span>
        </div>

        <Row style={{ marginTop: 15, justifyContent: "center" }}>
          <ul className="dates">
            <li className="f-w-100">Destino:</li>
          </ul>
        </Row>
        <Row style={{ marginTop: 5, justifyContent: "center" }}>
          <h5 className="f-w-700" style={{ marginLeft: 10 }}>
            {shipment.destination}
          </h5>
        </Row>

        <Row style={{ marginTop: 15, justifyContent: "center" }}>
          <ul className="dates">
            <li className="f-w-100">Descripción:</li>
          </ul>
        </Row>
        <Row style={{ marginTop: 5, justifyContent: "center" }}>
          <h5 className="f-w-200" style={{ marginLeft: 10 }}>
            {shipment.description}
          </h5>
        </Row>

        <Row style={{ marginTop: 15, justifyContent: "center" }}>
          <ul className="dates">
            <li className="f-w-100">Operado por:</li>
          </ul>
        </Row>
        <Row style={{ marginTop: 5, justifyContent: "center" }}>
          <h5 className="f-w-700" style={{ marginLeft: 10 }}>
            {getOperatorName(shipment)}
          </h5>
        </Row>

        <ul className="crm-activity w-full" style={{ width: '75%', alignSelf: 'center' }}>
          {shipment?.events.map((record) => {
            return (
              <li
                key={record._id}
                className="media"
                style={{ margin: 15, paddingTop: 15, justifyContent: "start" }}
              >
                <div
                  className="align-self-center media-body"
                  style={{ backgroundColor: "#F4F4F4", padding: "15px", borderRadius: "5px" }}
                >
                  <h5 className="mt-0" key={record._id}>
                    {record.text}
                  </h5>

                  <ul className="dates">
                    <li className="digits">
                      {dayjs(record.created_at).format("YYYY-MM-DD")}
                    </li>
                    <li className="digits">
                      {humanizeDuration(record.created_at)}
                    </li>
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      {shipment?.coords ? <Map shipment={shipment} /> : null}
    </div>
  );
}
