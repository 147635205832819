import {
  PROGRESS_SUCCESS,
  PROGRESS_ERROR,
  PROGRESS_INFO,
  PROGRESS_WARNING,
} from "./actionTypes";

export const toastSuccessAction = () => (dispatch) => {
  dispatch({
    type: PROGRESS_SUCCESS,
    payload: null,
  });
};

export const toastErrorAction = () => (dispatch) => {
  dispatch({
    type: PROGRESS_ERROR,
    payload: null,
  });
};

export const toastInfoAction = () => (dispatch) => {
  dispatch({
    type: PROGRESS_INFO,
    payload: null,
  });
};

export const toastWarningAction = () => (dispatch) => {
  dispatch({
    type: PROGRESS_WARNING,
    payload: null,
  });
};
