import {
  GET_SHIPMENT,
  GET_SHIPMENT_SUCCESS,
  GET_SHIPMENT_FAILED,
} from "./actionTypes";

export const INITIAL_STATE = {
  shipments: [],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_SHIPMENT:
      return {
        ...state,
        loading: true,
      };
    case GET_SHIPMENT_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case GET_SHIPMENT_FAILED:
      return {
        ...state,
        shipments: [],
        loading: false,
      };

    default:
      return state;
  }
};
