import { toast } from "react-toastify";

export const toastSuccess = (text = "Se creo correctamente") => {
  toast.success(text, {
    progress: true,
  });
};

export const toastError = (text = "Ha Ocurrido un Error") => {
  setTimeout(() => {
    toast(text, {
      progress: true,
    });
  }, 500);
};

export const toastInfo = (text = "Infomacion, intente nuevamente") => {
  setTimeout(() => {
    toast.info(text, {
      progress: true,
    });
  }, 500);
};

export const toastWarning = (text = "Peligro, intente nuevamente") => {
  setTimeout(() => {
    toast.warn(text, {
      progress: true,
    });
  }, 500);
};
