import React,{useState,useEffect} from 'react';

const Loader = ({timer=1000,forceClose=true}) => {

    const [show, setShow] = useState(true);

    useEffect(() => {
     const timerFn = setTimeout(() => {
            if(forceClose){
                setShow(false)
            }
          }, timer);
     return () => clearTimeout(timerFn);
    },[forceClose]);

    return (
        <div className={`loader-wrapper ${show ? '' : 'loderhide'}`}>
            <div className="typewriter">
                <h1>Shipbay Solutions.</h1>
            </div>
        </div>
    );
}

export default Loader;
