import React, {Fragment, useEffect, useState} from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Trash} from "react-feather";

//const
import {BASE_URL} from "../../../config/constants";
import {toastSuccess} from "../../common/toast";

const Drivers = (props) => {
  const [mydrivers, setDrivers] = useState([]);

  const getDrivers =()=>{
    fetch(BASE_URL + "/myDrivers", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
        .then((res) => res.json())
        .then((result) => {
          setDrivers(result.mydrivers);
        });
  }

  useEffect(() => {
    getDrivers();
  }, []);



  const deleteDriver = (driverId) => {
    fetch(BASE_URL + `/deleteDriver/${driverId}`, {
      method: "delete",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("jwt"),
      },
    })
        .then((res) => res.json())
        .then((result) => {
          console.log(result);
          getDrivers();
          toastSuccess("Operador eliminado correctamente")
        });
  };

  return (
    <Fragment>
      <Breadcrumb parent="Tracker" title="Operadores" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="activity-table table-responsive recent-table">
                  <table className="table table-bordernone">
                    <thead>
                      <tr>
                        <td>
                          <p className="mb-0">Foto</p>
                        </td>
                        <td>
                          <p className="mb-0">Nombre</p>
                        </td>
                        <td>
                          <p className="mb-0">Apellido</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {mydrivers.map((item,i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <img
                                alt=""
                                style={{
                                  width: "38px",
                                  height: "38px",
                                  borderRadius: "80px",
                                }}
                                src="https://res.cloudinary.com/shipbay/image/upload/v1644546610/Defaults/default-pp_rhozth.png"
                              />
                            </td>
                            <td>
                              <h6 className="f-w-700">{item.name}</h6>
                            </td>
                            <td>
                              <h6 className="f-w-700">{item.lastname}</h6>
                            </td>
                            <td></td>
                            <td>
                              <button
                                className="btn badge badge-danger"
                                onClick={()=>deleteDriver(item._id)}
                              >
                                <Trash />
                              </button>
                            </td>
                            <td></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Drivers;
