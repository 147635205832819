import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardHeader, CardBody } from "reactstrap";

//actions
import { getShipments } from "../../../redux/shipments/actions";
import { getShipmentStatusColorClass } from "../../../data";
import { getOperatorName } from "../../../utils";

const Resumen = (props) => {
  const dispatch = useDispatch();
  const { shipments } = useSelector((state) => state.Shipment);
  const { user } = useSelector((state) => state.Auth);

  useEffect(() => {
    dispatch(getShipments(user.type));
  }, []);

  return (
    <Fragment>
      <Breadcrumb parent="Inicio" title="Bienvenido" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Tu resumen</h5>
                <span>de envíos activos.</span>
              </CardHeader>
              <CardBody>
                <div className="activity-table table-responsive recent-table">
                  <table className="table table-bordernone">
                    <thead>
                      <tr>
                        <td>
                          <p className="mb-0">Salida</p>
                        </td>
                        <td>
                          <p className="mb-0">Destino</p>
                        </td>
                        <td>
                          <p className="mb-0">Código</p>
                        </td>
                        <td>
                          <p className="mb-0">Operado por</p>
                        </td>
                        <td>
                          <p className="mb-0">Status</p>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {shipments.map((item, index) => (
                        <tr key={item.id + index}>
                          <td style={{ maxWidth: "200px" }} className="mr-2">
                            <h6 className="f-w-700 text-truncate" style={{ paddingRight: "35px" }}>
                              {item.departure}
                            </h6>
                          </td>
                          <td style={{ maxWidth: "200px" }} className="mr-2">
                            <h6 className="f-w-700 text-truncate" style={{ paddingRight: "35px" }}>
                              {item.destination}
                            </h6>
                          </td>
                          <td>
                            <h5 className="default-text mb-0 f-w-700 f-18">
                              {item.id}
                            </h5>
                          </td>
                          <td>
                            <h6 className="f-w-700">{getOperatorName(item)}</h6>
                          </td>
                          <td>
                            <span
                              className={`text-white px-2 py-1 ${getShipmentStatusColorClass(
                                item.status
                              )}`}
                            >
                              {item.status}
                            </span>
                          </td>
                          <td>
                            <span className="badge badge-pill recent-badge">
                              <i data-feather="more-horizontal"></i>
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Resumen;
