import React from 'react'
export const MENUITEMS = [
    {
        title: 'Inicio', icon: <i className="pe-7s-home pe-lg"></i>, path: '/inicio/resumen', type: 'sub', active: true, bookmark: true, children: [
                {title: 'Resumen', type: 'sub'},
                { title: 'Tus Envíos', type: 'link', path: '/inicio/resumen', active: true, bookmark: true, }
        ]
    },
    {
        title: 'Tracker', icon: <i className="pe-7s-paper-plane"></i>,  path:  '/tracker/envios', type: 'sub',active: false, children: [
                { title: "Envíos", type: "sub" },
                { title: 'Todos tus envíos', type: 'link', path:  '/tracker/envios'},
                { title: 'Nuevo envío', type: 'link', path:  '/tracker/nuevo-envio'},
                { title: 'Controlador de eventos', type: 'link', path:  '/tracker/eventos'},
                { title: 'Compartir envíos', type: 'link', path:  '/tracker/comparte'},
                { title: "Operadores", type: "sub" },
                { title: 'Tus operadores', type: 'link', path:  '/tracker/operadores'},
                { title: 'Nuevo operador', type: 'link', path:  '/tracker/nuevo-operador'},
        ]
    },
    {
        title: 'Cuenta', icon: <i className="pe-7s-user"></i>,  path: '/cuenta/configuracion', type: 'sub',active: false, children: [
                {title: 'Tu Cuenta', type: 'sub'},
                { title: 'Configuración', type: 'link', path: '/cuenta/configuracion' },
        ]

    },
]
