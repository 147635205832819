
// starter kit
import Resumen from '../component/screens/Home/resumen'
import Shipments from '../component/screens/Tracker/shipments'
import NewShipment from '../component/screens/Tracker/newShipment'
import Drivers from '../component/screens/Tracker/drivers'
import Settings from '../component/screens/Account/settings'
import NewDriver from '../component/screens/Tracker/newdriver'
import Events from '../component/screens/Tracker/events'
import Share from '../component/screens/Tracker/shareShipment'

export const routes = [
    { path:"/inicio/resumen", Component: Resumen },
    { path:"/tracker/envios", Component: Shipments },
    { path:"/tracker/nuevo-envio", Component: NewShipment },
    { path:"/tracker/operadores", Component: Drivers },
    { path:"/tracker/nuevo-operador", Component: NewDriver },
    { path:"/tracker/eventos", Component: Events },
    { path:"/tracker/comparte", Component: Share },
    { path:"/cuenta/configuracion", Component: Settings },
]
