import React, { useState } from "react";
import { Col, Form, Input, Row, Button } from "reactstrap";
import Background from "../../../../assets/images/background.jpg";

export const ValidateOtp = ({
  data: { shipment },
  actions: { setAccessToInformation },
}) => {
  const [codeOtp, setCodeOtp] = useState("");
  const [messageError, setMessageError] = useState(false);

  const validateCodeOtp = (otp) => {
    const optLowerCase = shipment.id.toLowerCase();
    const validateCode = otp.toLowerCase();
    if (validateCode === optLowerCase) {
      setAccessToInformation(true);
      setMessageError(false);
    } else {
      setMessageError(true);
    }
  };

  return (
    <div className="cont text-center b-light">
      <Form
        className="form theme-form"
        onSubmit={(e) => {
          e.preventDefault();
          validateCodeOtp(codeOtp);
        }}
      >
      <div className="logo-wrapper">
        <img
          style={{ marginBottom: 35 }}
          src={require("../../../../assets/images/logo/logo.png")}
          alt=""
        />
      </div>
        <h3 className="f-w-700">Ingrese el código de identificación OTP</h3>
        <Row className="flex justify-content-center mt-4">
          <Col sm="8" md="7">
            <Input
              className="form-control input-air-primary"
              maxLength="10"
              minLength="10"
              type="text"
              onChange={(value) => setCodeOtp(value.currentTarget.value)}
            />
          </Col>
          <Col sm="3" md="3">
            <Button color="primary">Validar OTP</Button>
          </Col>
        </Row>
        {messageError && (
          <p className="f-w-700 mt-4 text-danger">
            El código ingresado es incorrecto, por favor valide su información
          </p>
        )}
      </Form>
    </div>
  );
};
