import React, { Fragment } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Button } from "reactstrap";
import { useDispatch } from "react-redux";
import { signOut } from "../../../redux/auth/actions";

const Settings = (props) => {
  const dispatch = useDispatch();
{/*  const hiddenFileInput = React.useRef(null);
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };*/}

  const logout = () => {
    dispatch(signOut());
  };

  return (
    <Fragment>
      <Breadcrumb parent="Cuenta" title="Configuración" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "18px 0px",
              }}
            >
              <div>
                <img
                  style={{
                    width: "160px",
                    height: "160px",
                    borderRadius: "80px",
                  }}
                  alt=""
                  src="https://res.cloudinary.com/shipbay/image/upload/v1644546610/Defaults/default-pp_rhozth.png"
                />
                <div style={{ marginTop: 35 }}>
                  <Button onClick={logout} color="danger">
                    Cerrar sesión
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Settings;
