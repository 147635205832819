import {
  USER_SIGN_IN,
  USER_SIGN_IN_FAILED,
  USER_SIGN_IN_SUCCESS,
  USER_SIGN_OUT,
} from "./actionTypes";

export const INITIAL_STATE = {
  user: null,
  loading: false,
  jwt: null,
  error: {
    flag: false,
    msg: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_SIGN_IN:
      return {
        ...state,
        loading: true,
      };
    case USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };

    case USER_SIGN_IN_FAILED:
      return {
        ...state,
        user: null,
        loading: false,
        error: {
          flag: true,
          msg: action.payload,
        },
      };
    case USER_SIGN_OUT:
      return {
        ...state,
        user: null,
        jwt: null,
        loading: false,
        error: {
          flag: false,
          msg: null,
        },
      };

    default:
      return state;
  }
};
